import { Box, Button, Pagination, TableCell, TablePagination, withStyles } from "@mui/material";
import dayjs from "dayjs";
import { useState, useCallback, useEffect } from "react";
import ListPage from "screens/Common/ListPage";
import { URLS } from "utils/constants/urls";
import axiosInstance from "utils/redux/axios";
import { useNavigate } from "react-router-dom";
import { CancelModal } from '../CancelModal'
import { useDispatch, useSelector } from "react-redux"
import OrderReturn from '../../OrderReturn'
import Loader from '../../../components/PageLoader'
import { InvoiceModal } from "../InvoiceModal";
import { Checkbox, Table, TableRow, TableCell as MuiTableCell, TableBody, TableHead } from "@mui/material"
import { setCheckedOrders, orderHistoryApiData, orderHistoryPage, orderHistoryRow, setPage, setRow, totalOrderHistoryElements } from "utils/redux/reducer/order-history-slice";
import './styles.css'
import { cancelEnquiry, returnEnquiry } from "./helper";
export const OrderHistory = ({ loading, setLoading, refresh }: { loading: any, setLoading: any, refresh: any }) => {
    const navigate = useNavigate();
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [pageResponse, setPageResponse] = useState<any>({});
    const [cancelData, setCancelData] = useState({} as any)
    const [openDrawer, setOpenDrawer] = useState(false);
    const dispatch = useDispatch();
    const [returnData, setReturnData] = useState<any>({});
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const data = useSelector(orderHistoryApiData)
    const onDrawerClose = () => {
        setOpenDrawer(false)
    }
    const pageConfig = {
        title: '',
        listApi: URLS.myOrders(),
        tableColumn: [
            { field: 'checkbox', headerName: '', flex: 1 },
            { field: 'action', headerName: "Action", flex: 1 },
            { field: 'orderId', headerName: 'Order No.', flex: 1, sortable: true },
            { field: 'orderDateString', headerName: 'Order Date/Time', flex: 1, sortable: true },
            { field: 'orderStatus', headerName: 'Order Status', flex: 1 },
            { field: 'invoiceNumber', headerName: "Invoice No.", flex: 1 },
            { field: 'productCode', headerName: 'Product Code', flex: 1, sortable: true },
            { field: 'productName', headerName: 'Product Name', flex: 1, sortable: true },
            { field: 'variantCode', headerName: 'Variant Code', flex: 1 },
            { field: 'variantName', headerName: 'Variant Name', flex: 1, sortable: true },
            { field: 'quantity', headerName: 'Ordered Quantity', flex: 1 },
            { field: 'cancelledQuantity', headerName: 'Cancelled Quantity', flex: 1 },
            { field: 'price', headerName: 'Price', flex: 1, sortable: true },
            { field: 'transactionId', headerName: 'Transaction Id', flex: 1, sortable: true },
            { field: 'loyaltyPoints', headerName: 'Loyalty Points', flex: 1, sortable: true },
        ],
        actionText: false
        // filterForm: AddAgentsForm(strings, 12),
    };
    const cancelButtonValues = [
        'PLACED',
        'RELEASE_ACCEPTED',
        'PARTIALLY_READY_TO_PICK',
        'READY_TO_PICK',
        'PARTIALLY_PICKED',
        'PARTIALLY_RELEASED',
        // 'PICKED',
        // 'PARTIALLY_READY_TO_PACK',
        // 'READY_TO_PACK',
        // 'PARTIALLY_PACKED',
        // 'PACKED',
        'RELEASED',
        'CREATED',
        // 'CARRIER_BOOKED',
        // 'OUT-FOR_PICKUP',
        'RELEASED',
        // 'IN_TRANSIT',
        // 'RECEIVED',

    ];

    //1200-CREATED  ,  1900-RELEASED  2100-RELEASE_ACCEPTED 1400-CARRIER_BOOKED 4300 OUT-FOR_PICKUP  4400 PICKED_UP 4700 IN_TRANSIT   4900 RECEIVED

    const returnButtonValues = [
        'DELIVERED', 'PICKED_UP'
    ]

    const onReturnButtonClick = async (data: any) => {
        const items = await returnEnquiry(data.orderNumber, data.products)
        if (items) {
            data.products = items
            setReturnData(data);
            setOpenDrawer(true)
        }
    }
    const onCancelButtonClick = async (data: any) => {
        const items = await cancelEnquiry(data.orderNumber, data.products)
        if (items) {
            data.products = items
            setCancelData(data)
            setOpenCancelModal(true);
        }
    }
    const getDateTimeFromEpoch = (milliseconds: string) => {// Your milliseconds value
        const rawDate = new Date(milliseconds);
        const formattedDate = rawDate.toLocaleDateString() + " " + rawDate.toLocaleTimeString();
        return formattedDate
    }

    const getActionButton = (obj: any) => {
        console.log(obj)
        const data = {
            orderNumber: obj.orderNumber,
            promiseId: obj.promiseId,
            posNo: obj?.customerOrder?.posNo,
            storeId: obj.customerOrder.storeId,
            products: [
                {
                    orderLineId: obj?.orderLineId,
                    productCode: obj.productCode,
                    variantCode: obj.variantCode,
                    quantity: obj.quantity,
                    amount: obj.amount,
                    orderedQuantity: obj.orderedQuantity,
                    productName: obj.productName,
                    variantName: obj.variantName,
                }
            ]
        }
        if (cancelButtonValues.includes(obj.status)) {
            return <Button onClick={() => {
                onCancelButtonClick(data);
            }}>Cancel</Button>
        }
        if (returnButtonValues.includes(obj.status)) {

            return <Button onClick={() => {
                onReturnButtonClick(data);
            }}>Return</Button>
        }
        return <></>
    }

    function getStatusByOrderLineId(orderData: any, orderLineId: any) {
        const orderLineItem = orderData.customerOrder.orderLineItems.find((item: any) => item.orderLineId === orderLineId);
        if (orderLineItem) {
            if (orderLineItem.status === "HOLD") return "CREATED"
            return (orderLineItem.status || "")
        }
        return null;
    }

    const getInvoiceButton = (invoiceNumber: any, orderNumber: any) => {
        if (invoiceNumber) {
            return <Button onClick={() => {
                setInvoiceNumber(invoiceNumber);
                setOpenInvoiceModal(true);
            }} href={"#"}>{invoiceNumber}</Button>
        }
        return <></>
    }

    const getQuantity = (q: number, cq: null | number) => {
        if (!cq) return q
        return q - cq
    }
    const getList = useCallback(
        async (filterData: any) => {
            setLoading(true)
            if (data.length)
                try {
                    const customData = {
                        ...data,
                        // content: data.content.map((item: any) => ({
                        //     ...item,
                        //     orderId: item.customerOrder.orderNumber,
                        //     orderDateString: getDateTimeFromEpoch(item.customerOrder.orderDate),
                        //     orderStatus: item.customerOrder.orderStatus,
                        //     transactionId: item.customerOrder.transactionId,
                        //     loyaltyPoints: item.customerOrder.redeemableLoyaltyPoints,
                        //     action: getActionButton({ status: item.customerOrder.orderStatus, variantCode: item.variantCode, quantity: item.quantity, orderNumber: item.customerOrder.orderNumber, productName: item.productName, variantName: item.variantName, productCode: item.productCode, amount: item.price })
                        // }))
                        content: data.map((item: any) => {
                            let customData = {
                                ...item,
                                invoiceNumber: getInvoiceButton(item.customerOrder.invoices.length ? item.customerOrder.invoices[0].invoiceNumber : '', item.customerOrder.orderNumber),
                                orderId: item.customerOrder.orderNumber,
                                orderDateString: getDateTimeFromEpoch(item.customerOrder.createdAt),
                                // orderStatus: (item.customerOrder.orderStatus || "").replaceAll('_', ' '),
                                orderStatus: (getStatusByOrderLineId(item, item.orderLineId) || "")?.replaceAll('_', ' '),
                                transactionId: item.customerOrder.transactionId,
                                loyaltyPoints: item.customerOrder.redeemableLoyaltyPoints,
                                checkbox: <Checkbox onChange={(e) => {
                                    dispatch(setCheckedOrders({ item: item, checked: e.target.checked }))
                                }} />,
                                action: getActionButton({ status: getStatusByOrderLineId(item, item.orderLineId), variantCode: item.variantCode, quantity: getQuantity(item.quantity, item.cancelledQuantity), orderedQuantity: item.quantity, orderNumber: item.customerOrder.orderNumber, productName: item.productName, variantName: item.variantName, productCode: item.productCode, amount: item.price, customerOrder: item.customerOrder, promiseId: item.customerOrder.promiseId, orderLineId: item.orderLineId })
                            }
                            return customData
                        })
                    }
                    setPageResponse(customData);

                } catch (error: any) {
                }
            setLoading(false);
        },
        [data, refresh]
    );

    useEffect(() => {
        (async () => {
            await getList(null);
        })();
    }, [data]);

    return <>
        <Box sx={{ width: '100%', position: 'relative' }}>
            {loading && <Loader />}
            {/* {data.length && <ListPage loading={loading} pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />} */}
            {pageResponse?.content && <HistoryTable data={pageResponse} tableHeader={pageConfig} />}
        </Box>
        <CancelModal open={openCancelModal} setOpen={setOpenCancelModal} tableData={cancelData} key={(cancelData as unknown) as React.Key} />
        <OrderReturn openDrawer={openDrawer} onDrawerClose={onDrawerClose} data={returnData as any} key={returnData as unknown as React.Key} />
        <InvoiceModal open={openInvoiceModal} setOpen={setOpenInvoiceModal} invoiceNumber={invoiceNumber} />

    </>

}

// { field: 'checkbox', headerName: '', flex: 1 },
// { field: 'action', headerName: "Action", flex: 1 },
// { field: 'orderId', headerName: 'Order No.', flex: 1, sortable: true },
// { field: 'orderDateString', headerName: 'Order Date/Time', flex: 1, sortable: true },
// { field: 'orderStatus', headerName: 'Order Status', flex: 1 },
// { field: 'invoiceNumber', headerName: "Invoice No.", flex: 1 },
// { field: 'productCode', headerName: 'Product Code', flex: 1, sortable: true },
// { field: 'productName', headerName: 'Product Name', flex: 1, sortable: true },
// { field: 'variantCode', headerName: 'Variant Code', flex: 1 },
// { field: 'variantName', headerName: 'Variant Name', flex: 1, sortable: true },
// { field: 'quantity', headerName: 'Ordered Quantity', flex: 1 },
// { field: 'cancelledQuantity', headerName: 'Cancelled Quantity', flex: 1 },
// { field: 'price', headerName: 'Price', flex: 1, sortable: true },
// { field: 'transactionId', headerName: 'Transaction Id', flex: 1, sortable: true },
// { field: 'loyaltyPoints', headerName: 'Loyalty Points', flex: 1, sortable: true },



const HistoryTable = ({ tableHeader, data }: { tableHeader: any, data: any }) => {
    const page = useSelector(orderHistoryPage)
    const row = useSelector(orderHistoryRow)
    const totalElements = useSelector(totalOrderHistoryElements)
    const dispatch = useDispatch();


    const handleChangePage = (e: any, number: any) => {
        dispatch(setPage(number));

    }
    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setRow(parseInt(e.target.value, 10)))
        dispatch(setPage(0));
    }
    return (
        <>
            <Table sx={{ overflow: 'auto', maxWidth: "100%" }}>
                <TableHead >
                    {
                        tableHeader.tableColumn.map((item: any, index: any) => (
                            <TableCell key={index} sx={{ backgroundColor: '#001529', color: '#fff' }}>
                                {item.headerName}
                            </TableCell>
                        ))
                    }
                </TableHead>
                <TableBody>
                    {
                        data.content.map((item: any) => (
                            <TableRow key={item.id} sx={{ maxHeight: "30px" }}>
                                <TableCell>{item.checkbox}</TableCell>
                                <TableCell>{item.action}</TableCell>
                                <TableCell>{item.orderId}</TableCell>
                                <TableCell>{item.orderDateString}</TableCell>
                                <TableCell>{item.orderStatus}</TableCell>
                                <TableCell>{item.invoiceNumber}</TableCell>
                                <TableCell>{item.productCode}</TableCell>
                                <TableCell>{item.productName}</TableCell>
                                <TableCell>{item.variantCode}</TableCell>
                                <TableCell>{item.variantName}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.cancelledQuantity}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.transactionId}</TableCell>
                                <TableCell>{item.loyaltyPoints}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={row}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
            />
        </>
    )
}

export default HistoryTable
